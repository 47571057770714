import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Firewall from './services/redirectors/Firewall';
import Loading from './services/common/loading';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Token, Ids } from './config/'

import Store from '../src/redux/store'
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './services/polyfill/polyfill';
import './App.scss';

//loading
const loading = Loading
// DefaultLayout
const DefaultLayout = Loadable({
    loader: () => import('./components/containers/index'),
    loading
});
// Login
const Login = Loadable({
    loader: () => import('./components/connexion/login'),
    loading
});
// Booking
const Booking = Loadable({
    loader: () => import('./components/booking/pages/Booking'),
    loading
});
// Login
const BookingConfigs = Loadable({
    loader: () => import('./components/booking/pages/BookingConfigs'),
    loading
});
// Logout
const Logout = Loadable({
    loader: () => import('./components/connexion/logout'),
    loading
});
// FullScreenPlanification
const FullScreenPlanification = Loadable({
    loader: () => import('./components/fullScreenPlanification'),
    loading
});

let innerWidthScreen = window.innerWidth;

//app class
class App extends Component {
    render() {
        let persistor = persistStore(Store,
            null,
            () => {
                const { token, idCustomer, idAgency, role } = Store.getState().userReducer;
                if (token !== null) { Token.setAccessToken(token) }
                if (idCustomer !== null) { Ids.setIdCustomer(idCustomer) }
                if (idAgency !== null) { Ids.setIdAgency(idAgency) }
                if (role !== null) { Ids.setRole(role) }
            },
        );
        return (
            //            innerWidthScreen > 800 ?
            <Provider store={Store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/booking/:idagency?/:idcounter?/:idagenda?/:idservice?" component={Booking} />
                            <Route exact path="/booking-config" component={BookingConfigs} />
                            
                            <Route exact path="/admin/login/:email?/:code?" component={Login} />
                            <Route exact path="/logout" component={Logout} />
                            <Route path="/plein-ecran-planning" name="FullScreenPlanification" component={FullScreenPlanification} />
                            <Firewall>
                                <Route path="/" name="Home" component={DefaultLayout} />
                            </Firewall>
                        </Switch>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
            //            :
            //            <span style={{textAlign: 'center !important'}}>800px min</span>
        );
    }
}
export default App;