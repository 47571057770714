let API_URL = 'https://inris-online.com/api';

if (process.env.NODE_ENV === "development") {
  //API_URL = 'https://inst-new-preprod-inris-online.maninwan.fr/api';
  API_URL = 'https://inris-online.com/api';
}
if (process.env.NODE_ENV === "production") {
  if(window.location.hostname === "preprod-customers.inris-online.com") {
    //API_URL = 'https://inst-new-preprod-inris-online.maninwan.fr/api';
    API_URL = 'https://inris-online.com/api';
  }
  if(window.location.hostname === "customers.inris-online.com") {
    API_URL = 'https://inris-online.com/api';
  }
}
/**config API_URL */
const config = {
  apiUrl: API_URL,
};

export default config;
