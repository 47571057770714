/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { axiosConfig } from './'
import moment from "moment/moment";
import 'moment/locale/fr';

const apiCall = {
  /****************************************************  Common routes ****************************************************/
  getEnpcAccess(id, role, iduser, idagency) {
    return axiosConfig.request("GET", `/participant/enpc/access/${id}/${role}/${iduser}/${idagency}`);
  },
  checkEmailExistance(email) {
    return axiosConfig.request("GET", `/check/mail/exists?email=${email}`);
  },
  dowloadFile(id, role, from, to, type) {
    if (type === 'ca') {
      return axiosConfig.request("GET", `/customer/export/invoices/${id}/${role}/${from}/${to}`);
    }
    else if (type === 'sn') {
      return axiosConfig.request("GET", `/customer/export/clients/inscrits/${id}/${role}/${from}/${to}`);
    }
  },
  downloadParticipantDocs(id, role, iduser, iddocument) {
    return axiosConfig.requestDownload("POST", `/participant/download/documents/${id}/${role}/${iduser}/${iddocument}`);
  },
  getTVAList() {
    return axiosConfig.request("GET", `/customer/list/tva`);
  },
  getPyamentDeadlinesList(id, role) {
    return axiosConfig.request("GET", `/customer/list/payment/deadlines/${id}/${role}`);
  },
  getStagesDescriptionsList() {
    return axiosConfig.request("GET", `/customer/list/stage/details`)
  },
  getPlanssDescriptionsList() {
    return axiosConfig.request("GET", `/customer/list/plan/details`)
  },
  getProposedFormationsList() {
    return axiosConfig.request("GET", `/list/formations`);
  },
  getAgenciesList(customerid, role) {
    return axiosConfig.request("GET", `/customer/list/agency/ref/${customerid}/${role}`);
  },
  getDigitalOffers(id) {
    return axiosConfig.request("GET", `/digital-offers`);
  },
  postLogin(email, password) {
    return axiosConfig.requestLogin("POST", "/auth-tokens/cri", {
      email,
      password
    });
  },
  postAutoLogin(email, password) {
    return axiosConfig.requestLogin("POST", "/auth-tokens/cri/autologin", {
      email,
      password
    });
  },
  postPwdForgotten(email) {
    return axiosConfig.requestLogin("POST", "/pwd/forgotten", {
      email,
    });
  },
  getUserByToken(token) {
    return axiosConfig.request("POST", "/laposte/login", {
      laposteIntegrationToken: token
    });
  },
  getListsForCustomer(id, role, idagency) {
    return axiosConfig.request("GET", `/customer/lists/clients/prospects/companies/${id}/${role}/${idagency}`);
  },
  getClientsShortList(id, role, idagency) {
    return axiosConfig.request("GET", `/customer/lists/clients/${id}/${role}/${idagency}`);
  },

  getCountsForCustomer(agencyId, role) {
    return axiosConfig.request("GET", `/customer/clients/count/${agencyId}/${role}`);
  },
  getCustomerPrestation(id, role) {
    return axiosConfig.request("GET", `/customer/prestations/list/${id}/${role}/0`)
  },
  getCustomerPrestationUnitary(id, role) {
    return axiosConfig.request("GET", `/customer/prestations/list/${id}/${role}/1`)
  },
  getCustomerPrestationServices(id, role) {
    return axiosConfig.request("GET", `/customer/prestations/list/${id}/${role}/2`)
  },
  getCustomerCreditSms(id, role) {
    return axiosConfig.request("GET", `/credits/and/campaigns/sms/${id}/${role}`)
  },
  getParticipantSolde(id, role, idagency, idclient) {
    return axiosConfig.request("GET", `/client/solde/${id}/${role}/${idagency}/${idclient}`)
  },
  getParticipantTimeCredit(id, role, idclient) {
    return axiosConfig.request("GET", `/client/credit/temps/${id}/${role}/${idclient}`)
  },
  getClientAddress(id, role, idclient) {
    return axiosConfig.request("GET", `/client/adresse/${id}/${role}/${idclient}`)
  },
  getPaymentStatus(id, role, idclient, idprospect) {
    return axiosConfig.request("GET", `/payment/status/${id}/${role}/${idclient}/${idprospect}`)
  },
  sendInfos(id, role, idclient, login, password) {
    return axiosConfig.requestSend("POST", `/participants/send/enpc/login/and/password/${id}/${role}/${idclient}`, {
      login,
      password
    });
  },
  postAddProspect(id, userInfos, role) {
    const firstname = userInfos.firstname;
    const lastname = userInfos.lastname;
    const sexe = userInfos.sexe;
    const email = userInfos.email;
    const phone = userInfos.mobile;
    const origin = userInfos.origin;
    const state = 'En attente';
    const comment = userInfos.comment;
    const agency = userInfos.agencyid;
    const formation = userInfos.formationselect

    return axiosConfig.requestSend("POST", `/propspect/customer/interface/add/${id}`, {
      role,
      //      sexe,
      firstname,
      lastname,
      email,
      phone,
      origin,
      agency,
      state,
      comment,
      formation
    }
    );
  },
  postAddCompany(id, agencyInfos, role, newAddress) {
    const name = agencyInfos.name
    const email = agencyInfos.email
    const phone = agencyInfos.phone
    const agency = agencyInfos.agencyid
    const socialReason = agencyInfos.socialReason
    const siret = agencyInfos.siret
    const vatNumber = agencyInfos.vatNumber
    const rib = agencyInfos.rib
    const iban = agencyInfos.iban
    const bic = agencyInfos.bic
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };

    return axiosConfig.requestSend("POST", `/company/customer/interface/add/${id}`, {
      role,
      name,
      email,
      phone,
      agency,
      socialReason,
      siret,
      vatNumber,
      rib,
      iban,
      bic,
      address,
    }
    );
  },
  deleteSomeData(
    id,
    role,
    type,
    iduser = null,
    idstage = null,
    idplan = null,
    idclient = null,
    idagenda = null,
    idagency = null,
    idpayment = null,
    idinvoice = null,
    idquote = null,
    idUnitServ = null,
    idSecretary = null,
    idPlace = null,
    idSessionUser = null
  ) {
    return axiosConfig.request("DELETE", `/cri/delete/${id}/${role}?type=${type}&idparticipant=${iduser}&idstage=${idstage}&idplan=${idplan}&idclient=${idclient}&idagenda=${idagenda}&idagency=${idagency}&idpayment=${idpayment}&idinvoice=${idinvoice}&idquote=${idquote}&idUnitServ=${idUnitServ}&idSecretary=${idSecretary}&idPlace=${idPlace}&idSessionUser=${idSessionUser}`);
  },
  editProspectComment(idprospect, comment) {
    return axiosConfig.requestSend("PATCH", `/propspect/comment/edit/${idprospect}`, {
      comment,
    }
    );
  },
  getClientTimeCredit(id, role, iduser) {
    return axiosConfig.request("GET", `/participant/timecredit/${id}/${role}/${iduser}`)
  },
  getAgendasLists(id, role, idagency) {
    return axiosConfig.request("GET", `/customer/agendas/${id}/${role}/${idagency}`);
  },
  /****************************************************  Meetings ************************************************/
  getDailyMeetings(agencyId, date) {
    return axiosConfig.request("GET", `/customer/daily/meetings/${agencyId}/${date}`);
  },
  getWeeklyMeetings(id, role, idagency, dateStart, dateEnd) {
    return axiosConfig.request("GET", `/customer/weekly/meetings/${id}/${role}/${idagency}/${dateStart}/${dateEnd}`);
  },
  getCustomerServicesList(id, role, idagency) {
    return axiosConfig.request("GET", `/customer/list/services/${id}/${role}/${idagency}`);
  },
  getParticipantInfosMeetings(id, role, iduser) {
    return axiosConfig.request("GET", `/participant/informations/planification/${id}/${role}/${iduser}`)
  },
  getPrintAgendaMeetings(id, role, idagency, idagenda, dateStart, dateEnd, data) {
    return axiosConfig.requestSend("GET", `/customer/meetings/by/agenda/${id}/${role}/${idagency}/${idagenda}/${dateStart}/${dateEnd}`, {
      data
    })
  },
  postAddMeeting(id, role, idagency, idagenda, idservice, idplace, meetingInfos, participants, selectedDate) {
    const date = moment(selectedDate).format('YYYYMMDDHHmm')
    const comment = meetingInfos.comment

    return axiosConfig.requestSend("POST", `/planification/register/meeting/${id}/${role}/${idagency}/${idagenda}/${idservice}/${idplace}`, {
      date,
      comment,
      participants
    }
    );
  },
  editMeeting(id, role, idagency, idagenda, idservice, idplace, idmeeting, meetingInfos, participants, selectedDate, type = false) {
    const date = moment(selectedDate).format('YYYYMMDDHHmm')
    const comment = meetingInfos.comment
    return axiosConfig.requestSend("PATCH", `/planification/edit/meeting/${id}/${role}/${idagency}/${idagenda}/${idservice}/${idplace}/${idmeeting}`, {
      date,
      comment,
      participants
    }
    );
  },
  /****************************************************  Home ****************************************************/
  getCustomerStats(agencyId, startDate, endDate, type) {
    return axiosConfig.request("GET", `/participants/customer/stats/${agencyId}/${startDate}/${endDate}/${type}`);
  },
  getCustomerRecapPropects(id, role) {
    return axiosConfig.request("GET", `/customer/recap/prospects/stats/${id}/${role}`)
  },
  /****************************************************  My clients space ****************************************************/
  getUserInfos(id, role, iduser) {
    return axiosConfig.request("GET", `/participants/infos/${iduser}/${id}/${role}`);
  },
  getUserLogin(id, role, iduser) {
    return axiosConfig.request("GET", `/participants/infos/login/${iduser}/${id}/${role}`);
  },
  sendUserLogin(id, role, iduser) {
    return axiosConfig.request("GET", `/send/login/${iduser}/${id}/${role}`);
  },
  getStudentsAppsList(iduser) {
    return axiosConfig.request("GET", `/participants/applications/${iduser}/enpc`);
  },
  getStudentsAppointments(iduser, id, role) {
    return axiosConfig.request("GET", `/customer/client/meetings/${id}/${role}?user=${iduser}`);
  },
  getStudentsInvoices(iduser, id, role) {
    return axiosConfig.request("GET", `/participants/customer/${iduser}/${id}/${role}/invoices`);
  },
  getStudentsQuotes(iduser, id, role) {
    return axiosConfig.request("GET", `/participants/customer/${iduser}/${id}/${role}/quotes`);
  },
  getStudentsPayments(iduser, id, role, idagency) {
    return axiosConfig.request("GET", `/participants/customer/${iduser}/${id}/${role}/${idagency}/payments`);
  },
  getStudentsRefunds(iduser, id, role, idagency) {
    return axiosConfig.request("GET", `/participants/customer/${iduser}/${id}/${role}/${idagency}/refunds`);
  },
  getStudentsContracts(iduser) {
    return axiosConfig.request("GET", `/participants/customer/${iduser}/contracts`);
  },
  exportCsv(id, role, iduser = 0, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/customer/export/client/invoices/${id}/${role}/${iduser}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/customer/export/client/invoices/${id}/${role}/${iduser}`);
    }
  },
  exportQuotesCsv(id, role, iduser = 0) {
    return axiosConfig.request("GET", `/customer/export/client/quotes/${id}/${role}/${iduser}`);
  },
  exportUserPaymentsCsv(id, role, iduser, idagency) {
    return axiosConfig.request("GET", `/customer/export/client/payments/${id}/${role}/${iduser}/${idagency}`);
  },
  exportAgencyPaymentsCsv(id, role, idagency, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/agency/export/payments/${id}/${role}/${idagency}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/agency/export/payments/${id}/${role}/${idagency}`);
    }
  },
  exportUserRefundsCsv(id, role, iduser = 0, idagency, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/customer/export/client/refunds/${id}/${role}/${iduser}/${idagency}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/customer/export/client/refunds/${id}/${role}/${iduser}/${idagency}`);
    }
  },
  exportBalanceCsv(id, role, iduser) {
    return axiosConfig.request("GET", `/customer/export/client/solde/${id}/${role}/${iduser}`);
  },
  importFromEnpc(id, role, idagency, type = null) {
    if (!type) {
      return axiosConfig.requestSend("PUT", `/participants/import/enpc/${id}/${role}/${idagency}`)
    } else if (type === 'instructor') {
      return axiosConfig.requestSend("PUT", `/personal/import/enpc/${id}/${role}/${idagency}/1`)
    } else if (type === 'secretary') {
      return axiosConfig.requestSend("PUT", `/personal/import/enpc/${id}/${role}/${idagency}/2`)
    }
  },
  postAddStudent(id, userInfos, role, newAddress) {
    const firstname = userInfos.firstname;
    const lastname = userInfos.lastname;
    const sexe = userInfos.sexe;
    const email = userInfos.email;
    const mobile = userInfos.mobile;
    const is_email = true;
    const is_sms = true;
    const birthdate = moment(userInfos.birthdate).format('DD/MM/YYYY');
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const agency_id = userInfos.agencyid;
    const formationChoisie = userInfos.formationselect

    return axiosConfig.requestSend("POST", `/participant/customer/interface/add/${id}`, {
      role,
      birthdate,
      firstname,
      lastname,
      email,
      sexe,
      is_email,
      mobile,
      is_sms,
      address,
      agency_id,
      formationChoisie
    }
    );
  },

  editStudent(id, role, iduser, userInfos, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };

    const birth_city = userInfos.birthCity
    const birth_country = userInfos.birthCountry
    const birth_department = userInfos.birthDepartment
    const birthdate = moment(userInfos.birthdate).format('YYYY-MM-DD');
    const email = userInfos.email;
    const firstname = userInfos.firstname
    const formationselect = userInfos.formationselect
    const lastname = userInfos.lastname
    const mobile = userInfos.mobile
    const nationality = userInfos.nationality
    const neph = userInfos.neph
    const note = userInfos.note
    const phone = userInfos.phone
    const sexe = userInfos.sexe;
    const agency_id = userInfos.agencyid;

    return axiosConfig.requestSend("PATCH", `/participant/customer/interface/edit/${id}/${role}/${iduser}`, {
      birth_city,
      birth_country,
      birth_department,
      birthdate,
      email,
      firstname,
      formationselect,
      lastname,
      mobile,
      nationality,
      neph,
      note,
      phone,
      sexe,
      agency_id,
      address
    }
    );
  },
  editClientNotes(id, role, iduser, note) {
    return axiosConfig.requestSend("PATCH", `/client/user/notes/edit/${id}/${role}/${iduser}`, {
      note
    }
    );
  },
  sendReview(id, role, iduser, idagency) {
    return axiosConfig.requestSend("POST", `/participant/customer/interface/send/review/${id}/${role}/${iduser}/${idagency}`)
  },
  lockUnlockParticipant(id, role, iduser) {
    return axiosConfig.requestSend("POST", `/customer/interface/participant/lock/unlock/${id}/${role}/${iduser}`)
  },
  lockUnlockInstructor(id, role, iduser) {
    return axiosConfig.requestSend("POST", `/customer/interface/instructor/lock/unlock/${id}/${role}/${iduser}`)
  },
  archiveUnarchiveParticipant(id, role, iduser, idagency) {
    return axiosConfig.requestSend("POST", `/customer/interface/participant/archive/unarchive/enpc/${id}/${role}/${iduser}/${idagency}`)
  },
  changeProspectState(id, role, idprospect, value) {
    return axiosConfig.requestSend("POST", `/customer/interface/prospect/change/state/${id}/${role}/${idprospect}`, {
      value
    })
  },
  convertProspect(id, role, idprospect) {
    return axiosConfig.requestSend("POST", `/customer/interface/convert/prospect/${id}/${role}/${idprospect}`)
  },
  getClientDocuments(id, role, iduser) {
    return axiosConfig.request("GET", `/user/documents/${id}/${role}/${iduser}`)
  },
  uploadClientDocument(id, role, iduser, file, fName) {
    let name = fName;
    return axiosConfig.requestSendDocument("POST", `/user/document/${id}/${role}/${iduser}`, {
      name,
      file
    })
  },
  deleteClientDocuments(id, role, iduser, iddocument) {
    return axiosConfig.request("DELETE", `/user/document/${id}/${role}/${iduser}/${iddocument}`);
  },

  /**************************************************** My intructors space ****************************************************/
  getAuthorizedAgendas(id, role) {
    return axiosConfig.request("GET", `/agendas/authorized/${id}/${role}`);
  },
  postAddInstructor(id, role, idagency, instructorInfos, isPublic = false, isIndependent = false, color, catAuto = false, catMoto = false, catBoat = false, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const sexe = instructorInfos.sexe
    const speaker_lastname = instructorInfos.lastname
    const speaker_firstname = instructorInfos.firstname
    const name = speaker_lastname + ' ' + speaker_firstname
    const speaker_email = instructorInfos.email
    const speaker_mobile = instructorInfos.mobile
    const birthdate = moment(instructorInfos.birthdate).format('YYYY-MM-DD');
    const is_independent = isIndependent
    const description = instructorInfos.description
    const is_public = isPublic
    const category_formation_auto = catAuto
    const category_formation_moto = catMoto
    const category_formation_boat = catBoat
    const auth_inst_city = instructorInfos.authInstCity
    const auth_inst_date = instructorInfos.authInstDate
    const auth_inst_number = instructorInfos.authInstNumber
    const medical_auth_inst_date = instructorInfos.medicalAuthInstDate
    const hourly_rate = instructorInfos.hourlyRate

    return axiosConfig.requestSend("POST", `/customer/interface/instructor/add/${id}/${role}/${idagency}`, {
      sexe,
      name,
      speaker_firstname,
      speaker_lastname,
      address,
      speaker_email,
      speaker_mobile,
      birthdate,
      is_independent,
      description,
      color,
      is_public,
      auth_inst_city,
      auth_inst_date,
      auth_inst_number,
      medical_auth_inst_date,
      hourly_rate
    })
  },
  patchEditInstructor(id, role, idAgenda, idagency, instructorInfos, isPublic = false, isIndependent = false, color, catAuto = false, catMoto = false, catBoat = false, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const sexe = instructorInfos.sexe
    const speaker_lastname = instructorInfos.lastname
    const speaker_firstname = instructorInfos.firstname
    const name = speaker_lastname + ' ' + speaker_firstname
    const speaker_email = instructorInfos.email
    const speaker_mobile = instructorInfos.mobile
    const birthdate = moment(instructorInfos.birthdate).format('YYYY-MM-DD');
    const is_independent = isIndependent
    const description = instructorInfos.description
    const is_public = isPublic
    const category_formation_auto = catAuto
    const category_formation_moto = catMoto
    const category_formation_boat = catBoat
    const auth_inst_city = instructorInfos.authInstCity
    const auth_inst_date = instructorInfos.authInstDate
    const auth_inst_number = instructorInfos.authInstNumber
    const medical_auth_inst_date = instructorInfos.medicalAuthInstDate
    const hourly_rate = instructorInfos.hourlyRate


    return axiosConfig.requestSend("PATCH", `/customer/interface/instructor/edit/${id}/${role}/${idAgenda}/${idagency}`, {
      sexe,
      name,
      speaker_firstname,
      speaker_lastname,
      address,
      speaker_email,
      speaker_mobile,
      birthdate,
      is_independent,
      description,
      color,
      is_public,
      auth_inst_city,
      auth_inst_date,
      auth_inst_number,
      medical_auth_inst_date,
      hourly_rate
    })
  },
  postAddInstructorSchedule(id, role, idagenda, schedule) {
    return axiosConfig.requestSend("POST", `/customer/interface/instructor/add/schedule/${id}/${role}/${idagenda}`, {
      schedule
    })
  },
  registerEnpcTeam(id, role, idmembre, idagency, type = null) {
    if (!type) {
      return axiosConfig.requestSend("POST", `/instructor/add/enpc/${id}/${role}/${idmembre}/${idagency}`)
    } else {
      return axiosConfig.requestSend("POST", `/secretary/add/enpc/${id}/${role}/${idmembre}/${idagency}`)
    }

  },
  registerSimulator(id, role, iduser, idagency) {
    return axiosConfig.requestSend("POST", `/participant/add/enpc/simulator/${id}/${role}/${iduser}/${idagency}`)
  },
  /**************************************************** My prestations space ****************************************************/
  getStagesListsForCustomer(id, role, isPassed = 0) {
    return axiosConfig.request("GET", `/customer/list/stages/${id}/${role}/${isPassed}`);
  },
  getStageTimeSheet(id, role, idagency, idstage) {
    return axiosConfig.request("GET", `/customer/stage/fiche/presence/${id}/${role}/${idagency}/${idstage}`);
  },
  getPlansListsForCustomer(id, role) {
    return axiosConfig.request("GET", `/customer/list/plans/${id}/${role}`);
  },
  getServicesAgendas(id, role, idService) {
    return axiosConfig.request("GET", `/customer/service/agendas/${id}/${role}/${idService}`);
  },
  postProduct(id, role, productInfos, amountIsHt) {
    const reference = productInfos.reference
    const name = productInfos.productName
    let price = productInfos.amount
    const vat = productInfos.vat
    const vatconfirm = productInfos.confirmVat
    const description = productInfos.description
    const isHT = amountIsHt

    return axiosConfig.requestSend("POST", `/customer/interface/produit/add/${id}/${role}`, {
      reference,
      name,
      price,
      vat,
      vatconfirm,
      description,
      isHT
    })
  },
  editProduct(id, role, idproduct, productInfos, amountIsHt) {
    const reference = productInfos.reference
    const name = productInfos.productName
    let price = productInfos.amount
    const vat = productInfos.vat
    const vatconfirm = productInfos.confirmVat
    const description = productInfos.description
    const isHT = amountIsHt

    return axiosConfig.requestSend("PATCH", `/customer/interface/produit/edit/${id}/${role}/${idproduct}`, {
      reference,
      name,
      price,
      vat,
      vatconfirm,
      description,
      isHT
    })
  },
  postService(id, role, serviceInfos, checkedList, color, selectedAgendas = false) {
    const name = serviceInfos.serviceName
    let price = serviceInfos.amount
    const vat = serviceInfos.vat
    const vatconfirm = serviceInfos.confirmVat
    const description = serviceInfos.description
    const maxParticipant = serviceInfos.placesNumber
    const duration = serviceInfos.duration
    const durationBeforeSms = serviceInfos.smsBefore
    const durationAfterSms = serviceInfos.smsAfter

    return axiosConfig.requestSend("POST", `/customer/interface/service/add/${id}/${role}`, {
      name,
      price,
      vat,
      vatconfirm,
      description,
      color,
      maxParticipant,
      duration,
      checkedList,
      durationBeforeSms,
      durationAfterSms,
      selectedAgendas
    })
  },
  editService(id, role, idService, serviceInfos, checkedList, color, selectedAgendas = false, ancientAgendas = false, idAgency = false) {
    const name = serviceInfos.serviceName
    let price = serviceInfos.amount
    const vat = serviceInfos.vat
    const vatconfirm = serviceInfos.confirmVat
    const description = serviceInfos.description
    const maxParticipant = serviceInfos.placesNumber
    const duration = serviceInfos.duration
    const durationBeforeSms = serviceInfos.smsBefore
    const durationAfterSms = serviceInfos.smsAfter

    return axiosConfig.requestSend("PATCH", `/customer/interface/service/edit/${id}/${role}/${idService}`, {
      name,
      price,
      vat,
      vatconfirm,
      description,
      color,
      maxParticipant,
      duration,
      checkedList,
      durationBeforeSms,
      durationAfterSms,
      selectedAgendas,
      ancientAgendas,
      idAgency
    })
  },
  postStage(id, role, stageInfos, isPublic, manualEndDate) {

    const id_stage_description = stageInfos.stage
    const start_date = stageInfos.startDate
    const end_date = (manualEndDate && stageInfos.endDate >= stageInfos.startDate) ? stageInfos.endDate : ''
    const tutor = stageInfos.tutor
    const nb_places = stageInfos.placesNumber
    const location = stageInfos.place
    const morning_hours = stageInfos.morningHours
    const afternoon_hours = stageInfos.afternoonHours
    const agency_id = stageInfos.establishment
    const is_public = isPublic

    return axiosConfig.requestSend("POST", `/customer/interface/stage/add/${id}/${role}`, {
      id_stage_description,
      start_date,
      end_date,
      tutor,
      nb_places,
      location,
      morning_hours,
      afternoon_hours,
      agency_id,
      is_public
    })
  },
  editStage(id, role, stageInfos, isPublic, idStage, idStageCategory) {
    const id_stage_description = idStageCategory
    const start_date = stageInfos.startDate
    const end_date = stageInfos.endDate >= stageInfos.startDate ? stageInfos.endDate : ''
    const tutor = stageInfos.tutor
    const nb_places = stageInfos.placesNumber
    const location = stageInfos.place
    const morning_hours = stageInfos.morningHours
    const afternoon_hours = stageInfos.afternoonHours
    const agency_id = stageInfos.establishment
    const is_public = isPublic

    return axiosConfig.requestSend("PATCH", `/customer/interface/stage/edit/${id}/${role}/${idStage}`, {
      id_stage_description,
      start_date,
      end_date,
      tutor,
      nb_places,
      location,
      morning_hours,
      afternoon_hours,
      agency_id,
      is_public
    })
  },

  postPlan(id, role, planInfos, isPublic, amountIsHt) {
    const id_plan_description = planInfos.formData.plan
    const price = planInfos.formData.price
    const name = planInfos.formData.title
    const is_public = isPublic
    const lines = planInfos.lines

    const vat = planInfos.formData.vat
    const vatconfirm = planInfos.formData.confirmVat
    const isHT = amountIsHt

    return axiosConfig.requestSend("POST", `/customer/interface/plan/add/${id}/${role}`, {
      id_plan_description,
      price,
      name,
      is_public,
      lines,
      vat,
      vatconfirm,
      isHT
    })
  },

  editPlan(id, role, idplan, planInfos, isPublic, amountIsHt) {
    const id_plan_description = planInfos.formData.plan
    const price = planInfos.formData.price
    const name = planInfos.formData.title
    const is_public = isPublic
    const lines = planInfos.lines

    const vat = planInfos.formData.vat
    const vatconfirm = planInfos.formData.confirmVat
    const isHT = amountIsHt

    return axiosConfig.requestSend("PATCH", `/customer/interface/plan/edit/${id}/${role}/${idplan}`, {
      id_plan_description,
      price,
      name,
      is_public,
      lines,
      vat,
      vatconfirm,
      isHT
    })
  },

  deletePlanProduct(id, role, idplan, idproduct) {
    return axiosConfig.request("DELETE", `/plan/product/delete/${id}/${role}/${idplan}/${idproduct}`);
  },

  postStageParticipant(id, role, iduser, idstage, sendmail) {
    return axiosConfig.requestSend("POST", `/stage/participant/add/${id}/${role}/${iduser}/${idstage}`, {
      sendmail
    })
  },
  informStagsParticipants(id, role, idstage) {
    return axiosConfig.requestSend("POST", `/customer/interface/inform/participants/stage/mail/${id}/${role}/${idstage}`)
  },
  postSendSms(id, role, idreceiver, idsender, smsContent) {
    return axiosConfig.requestSend("POST", `/participant/send/sms/${id}/${role}/${idreceiver}/${idsender}`, {
      smsContent
    })
  },
  /**************************************************** My accountig space ****************************************************/
  getPaymentsMethodsList() {
    return axiosConfig.request("GET", `/payments/modes/liste`);
  },
  getCustomerInvoices(id, role, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/customer/list/invoices/test/${id}/${role}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/customer/list/invoices/test/${id}/${role}`);
    }
  },
  getCustomerInvoicesShortList(id, role, iduser) {
    return axiosConfig.request("GET", `/customer/list/invoices/short/list/${id}/${role}/${iduser}`);
  },
  getCustomerQuotes(id, role, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/customer/list/devis/${id}/${role}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/customer/list/devis/${id}/${role}`);
    }
  },
  getCustomerRefunds(id, role, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/customer/list/avoirs/${id}/${role}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/customer/list/avoirs/${id}/${role}`);
    }
  },
  getInvoiceLinse(id, role, idinvoice) {
    return axiosConfig.request("GET", `/customer/invoice/${id}/${role}/${idinvoice}`)
  },
  getInvoiceById(id, role, idinvoice) {
    return axiosConfig.request("GET", `/invoice/by/id/${id}/${role}/${idinvoice}`)
  },
  getPaymentsCollection(id, role, idagency, startDate = false, endDate = false) {
    if ((startDate || endDate) && (startDate !== endDate)) {
      return axiosConfig.request("GET", `/payments/collection/${id}/${role}/${idagency}?start=${startDate}&end=${endDate}`);
    } else {
      return axiosConfig.request("GET", `/payments/collection/${id}/${role}/${idagency}`);
    }
  },
  sendPaymentsCollection(id, role, idpaymentcollection, idAgency) {
    return axiosConfig.requestSend("POST", `/send/payment/collection/${id}/${role}/${idpaymentcollection}/${idAgency}`);
  },
  editPaymentsCollectionComment(idpaymentcollection, comment) {
    return axiosConfig.requestSend("PATCH", `/payment/collection/comment/${idpaymentcollection}`, {
      comment,
    }
    );
  },
  editRefundComment(idrefund, comment) {
    return axiosConfig.requestSend("PATCH", `/refund/comment/${idrefund}`, {
      comment,
    }
    );
  },
  postAddInvoice(id, role, iduser, invoice) {
    return axiosConfig.requestSend("POST", `/customer/interface/invoice/add/${id}/${role}/${iduser}`, {
      invoice
    }
    );
  },
  editInvoiceComment(id, role, iduser, idinvoice, invoice) {
    return axiosConfig.requestSend("PATCH", `/invoice/comment/${id}/${role}/${iduser}/${idinvoice}`, {
      invoice
    }
    );
  },
  postAddInvoiceTimeCredit(id, role, iduser, invoice) {
    return axiosConfig.requestSend("POST", `/customer/interface/invoice/time/credit/add/${id}/${role}/${iduser}`, {
      invoice
    }
    );
  },
  postAddTimeCredit(id, role, idcustomeruser, iduser, timeCredit) {
    return axiosConfig.requestSend("POST", `/customer/interface/time/credit/add/${id}/${role}/${idcustomeruser}/${iduser}`, {
      timeCredit
    }
    );
  },
  postGenerateTimeCreditInvoiceById(id, role, idcustomeruser, idtimecredit) {
    const timeCredit = [];
    return axiosConfig.requestSend("POST", `/customer/interface/time/credit/generate/invoice/by/id/${id}/${role}/${idcustomeruser}/${idtimecredit}`, {
      timeCredit
    }
    );
  },
  postAddQuote(id, role, iduser, quote) {
    return axiosConfig.requestSend("POST", `/customer/interface/quote/add/${id}/${role}/${iduser}`, {
      quote
    }
    );
  },
  editQuote(id, role, iduser, idquote, quote) {
    return axiosConfig.requestSend("PATCH", `/customer/interface/quote/edit/${id}/${role}/${iduser}/${idquote}`, {
      quote
    }
    );
  },
  deleteQuoteLine(id, role, idquote, idline) {
    return axiosConfig.request("DELETE", `/quote/line/delete/${id}/${role}/${idquote}/${idline}`);
  },

  postTransformToInvoice(idquote, id, role) {
    return axiosConfig.requestSend("POST", `/customer/interface/quote/generate/invoice/${idquote}/${id}/${role}`);
  },
  postAddPayment(idinvoice, id, role, paymentInfo) {
    const amount = paymentInfo.amount
    const paidAt = moment(paymentInfo.paymentDate).format('DD-MM-YYYY');
    const paymentMethod = paymentInfo.paymentMethod
    const transaction = paymentInfo.reference
    const comment = paymentInfo.comment

    return axiosConfig.requestSend("POST", `/customer/interface/invoice/add/payment/${idinvoice}/${id}/${role}`, {
      amount,
      paidAt,
      paymentMethod,
      transaction,
      comment
    });
  },
  postAddPaymentTimeCredit(id, role, idcustomeruser, iduser, idtimecredit, paymentInfo) {

    const amount = paymentInfo.amount
    const paidAt = moment(paymentInfo.paymentDate).format('DD-MM-YYYY');
    const paymentMethod = paymentInfo.paymentMethod
    const transaction = paymentInfo.reference
    const comment = paymentInfo.comment

    return axiosConfig.requestSend("POST", `/customer/interface/time/credit/add/payment/${id}/${role}/${idcustomeruser}/${iduser}/${idtimecredit}`, {
      amount,
      paidAt,
      paymentMethod,
      transaction,
      comment
    });
  },
  postAddCredit(iduser, id, role, CreditInfo, idinvoice = null) {
    const amount = CreditInfo.amount
    return axiosConfig.requestSend("POST", `/customer/interface/credit/add/${iduser}/${id}/${role}/${idinvoice}`, {
      amount
    });
  },
  postSendInvoice(iduser, id, role, idinvoice) {
    return axiosConfig.requestSend("POST", `/customer/interface/send/invoice/mail/${iduser}/${id}/${role}/${idinvoice}`);
  },
  postSendQuote(iduser, id, role, idquote) {
    return axiosConfig.requestSend("POST", `/send/quote/mail/${iduser}/${id}/${role}/${idquote}`);
  },
  sendRefund(id, role, idrefund, idAgency) {
    return axiosConfig.requestSend("POST", `/send/refund/${id}/${role}/${idrefund}/${idAgency}`);
  },
  patchFinaliseInvoice(id, role, idinvoice) {
    return axiosConfig.requestSend("PATCH", `/customer/interface/finalise/invoice/${id}/${role}/${idinvoice}`);
  },
  postPrintInvoice(iduser, id, role, idinvoice) {
    return axiosConfig.requestDownload("POST", `/customer/interface/pdf/invoice/${iduser}/${id}/${role}/${idinvoice}`);
  },
  postPrintQuote(iduser, id, role, idquote) {
    return axiosConfig.requestDownload("POST", `/pdf/quote/${iduser}/${id}/${role}/${idquote}`);
  },
  postPrintPaymentCollection(id, role, idpaymentcollection, idagency) {
    return axiosConfig.requestDownload("POST", `/pdf/payment/collection/${id}/${role}/${idpaymentcollection}/${idagency}`);
  },
  postPrintRefund(id, role, idrefund, idagency) {
    return axiosConfig.requestDownload("POST", `/pdf/refund/${id}/${role}/${idrefund}/${idagency}`);
  },
  changeQuoteState(id, role, idquote, value) {
    return axiosConfig.requestSend("POST", `/customer/interface/quote/change/state/${id}/${role}/${idquote}`, {
      value
    })
  },
  /**************************************************** My profil nav space ****************************************************/
  editProfile(id, role, iduser, userInfos, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };

    const birthdate = moment(userInfos.birthdate).format('YYYY-MM-DD');
    const email = userInfos.email;
    const firstname = userInfos.firstname
    const lastname = userInfos.lastname
    const mobile = userInfos.mobile
    const phone = userInfos.phone
    const sexe = userInfos.sexe;

    return axiosConfig.requestSend("PATCH", `/profile/edit/${id}/${role}/${iduser}`, {
      birthdate,
      email,
      firstname,
      lastname,
      mobile,
      phone,
      sexe,
      address
    }
    );
  },
  getAgencyInfos(id, role, idagency) {
    return axiosConfig.request("GET", `/agency/customer/interface/infos/${id}/${role}/${idagency}`);
  },
  getAuthorizedAgencies(id, role) {
    return axiosConfig.request("GET", `/agencies/authorized/${id}/${role}`);
  },
  AddAgency(id, role, agencyInfos, checkedList, newAddress, selectedFormations) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country,
      latitude: newAddress?.latitude,
      longitude: newAddress?.longitude
    };
    const newCheckedList = {
      'poleEmploiF': checkedList['poleEmploiF'] ? checkedList['poleEmploiF'] : '',
      'CpfF': checkedList['CpfFAdd'] ? checkedList['CpfFAdd'] : '',
      'permis1eF': checkedList['permis1eFAdd'] ? checkedList['permis1eFAdd'] : '',
      'permis3xF': checkedList['permis3xFAdd'] ? checkedList['permis3xFAdd'] : '',
      'locationDcF': checkedList['locationDcFAdd'] ? checkedList['locationDcFAdd'] : '',
      'kadodriveF': checkedList['kadodriveFAdd'] ? checkedList['kadodriveFAdd'] : '',
      'datalocke': checkedList['datalockeAdd'] ? checkedList['datalockeAdd'] : '',
      'qualiopi': checkedList['qualiopiAdd'] ? checkedList['qualiopiAdd'] : '',
      'labellise': checkedList['labelliseAdd'] ? checkedList['labelliseAdd'] : '',
      'status': checkedList['statusAdd'] ? checkedList['statusAdd'] : ''
    }

    return axiosConfig.requestSend("POST", `/agency/add/${id}/${role}`, {
      agencyInfos,
      newCheckedList,
      address,
      selectedFormations
    })
  },
  editAgencyInfos(id, role, idAgency, agencyInfos, checkedList, newAddress, selectedFormations) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country,
      latitude: newAddress?.latitude,
      longitude: newAddress?.longitude
    };
    return axiosConfig.requestSend("PATCH", `/agency/customer/interface/edit/${id}/${role}/${idAgency}`, {
      agencyInfos,
      checkedList,
      address,
      selectedFormations
    })
  },
  getSecretariesLists(id, role, idagency) {
    return axiosConfig.request("GET", `/secretary/customer/list/${id}/${role}/${idagency}`);
  },
  postAddSecretary(id, role, idagency, secretaryInfos, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const firstname = secretaryInfos.firstname;
    const lastname = secretaryInfos.lastname;
    const sexe = secretaryInfos.sexe;
    const email = secretaryInfos.email;
    const mobile = secretaryInfos.mobile;
    const phone = secretaryInfos.phone;
    const is_email = true;
    const is_sms = true;
    const birthdate = moment(secretaryInfos.birthdate).format('YYYY-MM-DD');

    return axiosConfig.requestSend("POST", `/secretary/customer/interface/add/${id}/${role}/${idagency}`, {
      birthdate,
      firstname,
      lastname,
      email,
      sexe,
      is_email,
      mobile,
      phone,
      is_sms,
      address,
    }
    );
  },

  editSecretary(id, role, idagency, idsecretary, secretaryInfos, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const firstname = secretaryInfos.firstname;
    const lastname = secretaryInfos.lastname;
    const sexe = secretaryInfos.sexe;
    const email = secretaryInfos.email;
    const mobile = secretaryInfos.mobile;
    const phone = secretaryInfos.phone;
    const is_email = true;
    const is_sms = true;
    const birthdate = moment(secretaryInfos.birthdate).format('YYYY-MM-DD');

    return axiosConfig.requestSend("PATCH", `/secretary/customer/interface/edit/${id}/${role}/${idagency}/${idsecretary}`, {
      birthdate,
      firstname,
      lastname,
      email,
      sexe,
      is_email,
      mobile,
      phone,
      is_sms,
      address,
    }
    );
  },

  getCustomerSettings(id, role) {
    return axiosConfig.request("GET", `/customer/settings/${id}/${role}`);
  },
  editSettings(id, role, idagency, settingsInfos) {
    return axiosConfig.requestSend("PATCH", `/agency/settings/customer/interface/edit/${id}/${role}/${idagency}`, {
      settingsInfos
    }
    );
  },
  getPlacesList(id, role, idagency) {
    return axiosConfig.request("GET", `/places/customer/list/${id}/${role}/${idagency}`);
  },
  postAddPlace(id, role, idagency, placeInfos, favorite, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const name = placeInfos.name;
    const description = placeInfos.description;

    return axiosConfig.requestSend("POST", `/agency/place/customer/interface/add/${id}/${role}/${idagency}`, {
      name,
      description,
      address,
      favorite,
    }
    );
  },
  editPlace(id, role, idagency, idplace, placeInfos, favorite, newAddress) {
    const address = {
      street: (newAddress.street_number ? newAddress.street_number + ' ' : '') + newAddress?.street,
      postal_code: newAddress?.postal_code,
      city: newAddress?.city,
      country: newAddress?.country
    };
    const name = placeInfos.name;
    const description = placeInfos.description;

    return axiosConfig.requestSend("PATCH", `/agency/place/customer/interface/edit/${id}/${role}/${idagency}/${idplace}`, {
      name,
      description,
      address,
      favorite,
    }
    );
  },
  getAgencyCard(id, role, idagency) {
    return axiosConfig.request("GET", `/agency/card/${id}/${role}/${idagency}`);
  },
  editAgencyCard(id, role, idagency, cardInfos) {
    return axiosConfig.requestSend("PUT", `/agency/card/edit/${id}/${role}/${idagency}`, {
      cardInfos
    }
    );
  },
  getAgencyHours(id, role, idagency, type = 0) {
    // type = 0 pour les horaires d'ouverture et = 1 pour les horaires de conduite
    return axiosConfig.request("GET", `/agency/hours/${id}/${role}/${idagency}/${type}`);
  },
  patchHoursAgency(id, role, idagency, hoursInfos, type) {
    return axiosConfig.requestSend("PATCH", `/agency/hours/edit/${id}/${role}/${idagency}`, {
      hoursInfos,
      type
    }
    );
  },
  creditSms(id, role, creditSms) {
    return axiosConfig.requestSend("PUT", `/customer/sms/credit/${id}/${role}`, {
      creditSms
    }
    );
  },
  postCompaignSms(id, role, idagency, compaignInfos, cible, isRecap, state = '') {
    return axiosConfig.requestSend("POST", `/compaign/sms/${id}/${role}/${idagency}/${isRecap}`, {
      compaignInfos,
      cible,
      state
    }
    );
  },
  getCustomerDocuments(id, role, idagency, type) {
    return axiosConfig.request("GET", `/customer/documents/${id}/${role}/${idagency}/${type}`);
  },
  editCustomerDocuments(id, role, iddocument, documentInfos, content) {
    const name = documentInfos.name
    return axiosConfig.requestSend("PUT", `/customer/document/edit/${id}/${role}/${iddocument}`, {
      name,
      content
    })
  },
  deleteDocument(id, role, iddocument) {
    return axiosConfig.request("DELETE", `/cri/delete/document/${id}/${role}/${iddocument}`);
  },
  deleteDocumentLabelise(id, role, idagency, itemTitle) {
    return axiosConfig.request("DELETE", `/cri/delete/document/lablise/${id}/${role}/${idagency}?field=${itemTitle}`);
  },
  deleteMeeting(id, role, idmeeting) {
    return axiosConfig.request("DELETE", `/cri/delete/meeting/${id}/${role}/${idmeeting}`);
  },
  deleteTimeCredit(id, role, idcustomeruser, iduser, idtimecredit) {
    return axiosConfig.request("DELETE", `/cri/delete/time/credit/${id}/${role}/${idcustomeruser}/${iduser}/${idtimecredit}`);
  },

  getPartner(iduser) {
    return axiosConfig.request("GET", `/partenaire/offres/${iduser}`);
  },
  postUploadAgencyImage(id, role, idagency, image) {
    return axiosConfig.requestSendFile("POST", `/agency/upload/image/${id}/${role}/${idagency}`, {
      image
    })
  },
  postUploadClientImage(id, role, iduser, avatar) {
    return axiosConfig.requestSendFile("POST", `/participant/change/avatar/cri/${id}/${role}/${iduser}`, {
      avatar
    })
  },
  /****************************************************  Enpc ****************************************************/
  getCustomerAppsEnpcValidationList(id, role, iduser, idagency, appRef) {
    return axiosConfig.request("GET", `/customer/agency/list/enpc/apps/${id}/${role}/${iduser}/${idagency}/${appRef}`);
  },
  getCustomerAppsEnpcValidationListArray(id, role, iduser, idagency) {
    //if we want to check our list app we have to uncomment the code under, and we have to add this in the url ------> ?${refs} <---------
    /*let refsData = [1, 12, 16, 9, 11, 14, 20, 19, 10, 15, 17, 18, 4, 7, 2, 5, 3, 6];
    let refs = refsData.map((el, idx) => {
      return 'refs[' + idx + ']=' + el;
    }).join('&');
    //*/
    return axiosConfig.request("GET", `/customer/agency/list/enpc/apps/array/${id}/${role}/${iduser}/${idagency}`);
  },

  getStudentStats(id, role, iduser, typepermis, typestats) {
    return axiosConfig.request("GET", `/participant/stats/enpc/${id}/${role}/${iduser}/${typepermis}/${typestats}`);
  },
  postEnpc(id, role, iduser, enpcInfos = null) {
    const dataSendPermis = enpcInfos.dataSendPermis
    const dataSendApplication = enpcInfos.dataSendApplication
    const offerDuration = enpcInfos.offerDuration

    return axiosConfig.requestSend("POST", `/customer/interface/enpc/create/${id}/${role}/${iduser}`, {
      dataSendPermis,
      dataSendApplication,
      offerDuration,
    });
  },
  postEvalClick(id, role, iduser, enpcInfos = null) {
    const dataSendPermis = enpcInfos.dataSendPermis
    const dataSendApplication = enpcInfos.dataSendApplication
    const offerDuration = enpcInfos.offerDuration

    return axiosConfig.requestSend("POST", `/eval/click/${id}/${role}/${iduser}`, {
      dataSendPermis,
      dataSendApplication,
      offerDuration,
    });
  },
  getEvalResults(id, role, iduser) {
    return axiosConfig.request("GET", `/eval/resultat/${id}/${role}/${iduser}`);
  }
};
// ./ end

export default apiCall


