import axios from 'axios';
import { Token, config } from './';

//access token
const axiosConfig = {
    requestLogin: async function requestLogin(METHOD_TYPE, URI, DATA = null) {
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;
        // Make Request
        const response = await axios({
            method: method_type,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: DATA
        });
        return response
    },

    request: async function request(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;
        // Make Request
        const response = await axios({
            method: method_type,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            data: DATA
        });
        if (response.status === 200 || response.status === 201) return response.data
        else return response.status
        //*/
    },
    requestPlanification: async function request(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;
        // Make Request
        const response = await axios({
            method: method_type,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            data: DATA
        });
        return response
        //*/
    },
    requestSend: async function requestSend(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const options = {
            method: method_type,
            url: url,
            data: DATA,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            transformResponse: [(data) => {
                return data;
            }]
        };

        // send the request
        return axios(options);
    },
    requestDownload: async function requestDownload(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const options = {
            method: method_type,
            url: url,
            data: DATA,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            responseType: 'blob', //Force to receive data in a Blob Format
        };
        // send the request
        return axios(options);
    },
    requestDownloadFile: async function requestDownloadFile(METHOD_TYPE, PATH, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + "/uploads/inris-documents/" + PATH;

        axios({
            method: method_type,
            url: url, //your url
            responseType: 'blob', // important
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    },
    requestSendFile: async function requestSendFile(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const formData = new FormData();
        formData.append('avatar', DATA.avatar)
        //formData = JSON.stringify(formData)

        const options = {
            method: method_type,
            url: url,
            data: formData,
            headers: {
                'X-Auth-Token': accessToken,
                'Content-Type': `multipart/form-data; boundary=${formData.boundary}`,
            }
        };
        // send the request
        return axios(options);
        //*/
    },
    requestSendDocument: async function requestSendDocument(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const formData = new FormData();
        formData.append('file', DATA.file)
        formData.append('name', DATA.name)
        //formData = JSON.stringify(formData)

        const options = {
            method: method_type,
            url: url,
            data: formData,
            headers: {
                'X-Auth-Token': accessToken,
                'Content-Type': `multipart/form-data; boundary=${formData.boundary}`,
            }
        };
        // send the request
        return axios(options);
        //*/
    },

    requestSendEnpc: async function requestSendEnpc(METHOD_TYPE, URI, DATA = null, timeStamp) {
        const secret = 'd35skzmt7inris4g81e1b31xx2gxqi66svek7cud7acjyihl';
        const baseUrl = 'https://api.enpc-center.fr';
        const apiKey = 'gcer9inris8k071tlp8lqfbgfls1umez3i1agmh74mc48lrd';
        const url = baseUrl + URI + '?timestamp=' + timeStamp;

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const crypto = require('crypto');
        const signature = crypto.createHmac('md5', secret).update(url).digest().toString('base64');
        const headers = {
            'apikey': apiKey,
            'signature': signature,
            'content-type': 'application/json'
        }
        const method_type = METHOD_TYPE.toLowerCase();
        const options = {
            method: method_type,
            url: url,
            data: DATA,
            headers: headers,
            transformResponse: [(data) => {
                return data;
            }]
        };
        // send the request
        return axios(options);
    },
}

export default axiosConfig;