import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';
import { Token } from '../../config/';

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogged: false,
    user: null,
    token: null,
    loggedAt: null,
    idCustomer: null,
    isProspections: true,
    role: null,
    idAgency: null,
    isStats: false
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLogged = action.payload.isLogged;
      state.loggedAt = action.payload.loggedAt;
      state.idCustomer = action.payload.idCustomer;
      state.isProspections = action.payload.isProspections;
      state.role = action.payload.role;
      state.idAgency = action.payload.idAgency;
      state.isStats = action.payload.isStats;
    },
    setIdAgency: (state, action) => {
      state.idAgency = action.payload.idAgency;
    },
    setIsProspections: (state, action) => {
      state.isProspections = action.payload.isProspections;
    },
    setProfile: (state, action) => {
      state.user = action.payload.user;
    }
  },
});

export const { setUser, setIdAgency, setIsProspections, setProfile } = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const loadUserAsync = (data, idCustomer, role, idAgency, isStats) => dispatch => {
  Token.setAccessToken(data.token);
  const user = {
    user: data.user,
    token: data.token,
    isLogged: true,
    loggedAt: Date.now(),
    idCustomer: idCustomer,
    isProspections: true,
    role: role,
    idAgency: idAgency,
    isStats: isStats,
  }
  setTimeout(() => {
    dispatch(setUser(user));
  }, 1000);
};

export const logoutUser = () => dispatch => {
  const user = {
    isLogged: false,
    user: null,
    token: null,
    loggedAt: null,
    idCustomer: null,
    role: null,
    idAgency: null,
    isStats: false
  }
  dispatch(setUser(user));
}

export const changeIdAgency = (id) => dispatch => {
  const user = {
    idAgency: id,
  }
  setTimeout(() => {
    dispatch(setIdAgency(user));
  }, 1000);
};

export const changeIsProspections = (value) => dispatch => {
  const user = {
    isProspections: value,
  }
  setTimeout(() => {
    dispatch(setIsProspections(user));
  }, 1000);
};
export const updateProfile = (data) => dispatch => {
  const user = {
    user: data,
  }
  setTimeout(() => {
    dispatch(setProfile(user));
  }, 1000);
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.userReducer.user;
export const selectToken = state => state.userReducer.token;
export const selectIsLogged = state => state.userReducer.isLogged;
export const selectLoggedAt = state => state.userReducer.loggedAt;
export const selectIdCustomer = state => state.userReducer.idCustomer;
export const selectIsProspections = state => state.userReducer.isProspections;
export const selectRole = state => state.userReducer.role;
export const selectIdAgency = state => state.userReducer.idAgency;
export const selectIsStats = state => state.userReducer.isStats;

export default userSlice.reducer;
