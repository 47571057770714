class Ids {

    setIdCustomer(idCustomer) {
        this.idCustomer = idCustomer;
    }

    getIdCustomer() {
        return this.idCustomer;
    }

    setIdAgency(idAgency) {
        this.idAgency = idAgency;
    }

    getIdAgency() {
        return this.idAgency;
    }

    setRole(role) {
        this.role = role;
    }
    getRole() {
        return this.role;
    }

}

export default new Ids();
