class Token {
    setAccessToken(accessToken) {
      this.accessToken = accessToken;
    }
  
    getAccessToken() {
      return this.accessToken;
    }
  }
  
  export default new Token();
  